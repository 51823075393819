import * as i1 from '@angular/common';
import { isPlatformServer, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Injector, Output, ViewChild, ViewChildren, PLATFORM_ID, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1$1 from '@ngneat/overview';
import { isTemplateRef, isComponent, DynamicViewModule } from '@ngneat/overview';
import { Subject, race, defer } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
const _c0 = ["*"];
const _c1 = (a0, a1) => ({
  "border-color": a0,
  "border-right-color": a1
});
function IndicatorComponent_div_0_div_4_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "\n        ");
    i0.ɵɵelement(2, "hot-toast-error", 2);
    i0.ɵɵtext(3, "\n      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_div_0_div_4_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "\n        ");
    i0.ɵɵelement(2, "hot-toast-checkmark", 2);
    i0.ɵɵtext(3, "\n      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_div_0_div_4_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "\n        ");
    i0.ɵɵelement(2, "hot-toast-warning", 2);
    i0.ɵɵtext(3, "\n      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_div_0_div_4_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "\n        ");
    i0.ɵɵelement(2, "hot-toast-info", 2);
    i0.ɵɵtext(3, "\n      ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("theme", ctx_r0.theme);
  }
}
function IndicatorComponent_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1, "\n    ");
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵtext(3, "\n      ");
    i0.ɵɵtemplate(4, IndicatorComponent_div_0_div_4_div_4_Template, 4, 1, "div", 6);
    i0.ɵɵtext(5, "\n      ");
    i0.ɵɵtemplate(6, IndicatorComponent_div_0_div_4_div_6_Template, 4, 1, "div", 6);
    i0.ɵɵtext(7, "\n      ");
    i0.ɵɵtemplate(8, IndicatorComponent_div_0_div_4_div_8_Template, 4, 1, "div", 6);
    i0.ɵɵtext(9, "\n      ");
    i0.ɵɵtemplate(10, IndicatorComponent_div_0_div_4_div_10_Template, 4, 1, "div", 6);
    i0.ɵɵtext(11, "\n    ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(12, "\n  ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitch", ctx_r0.type);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitchCase", "error");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitchCase", "success");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitchCase", "warning");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngSwitchCase", "info");
  }
}
function IndicatorComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtext(1, "\n  ");
    i0.ɵɵelement(2, "hot-toast-loader", 2);
    i0.ɵɵtext(3, "\n  ");
    i0.ɵɵtemplate(4, IndicatorComponent_div_0_div_4_Template, 13, 5, "div", 3);
    i0.ɵɵtext(5, "\n");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("theme", ctx_r0.theme);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.type !== "loading");
  }
}
const _c2 = ["hotToastBarBase"];
function HotToastComponent_ng_container_7_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1, "\n          ");
    i0.ɵɵelementStart(2, "hot-toast-animated-icon", 10);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4, "\n        ");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("iconTheme", ctx_r0.toast.iconTheme);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.toast.icon);
  }
}
function HotToastComponent_ng_container_7_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n          ");
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵtext(2, "\n            ");
    i0.ɵɵelementContainer(3, 11);
    i0.ɵɵtext(4, "\n          ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, "\n        ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dynamicView", ctx_r0.toast.icon);
  }
}
function HotToastComponent_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1, "\n        ");
    i0.ɵɵtemplate(2, HotToastComponent_ng_container_7_ng_container_2_Template, 5, 2, "ng-container", 6);
    i0.ɵɵtext(3, "\n        ");
    i0.ɵɵtemplate(4, HotToastComponent_ng_container_7_ng_template_4_Template, 6, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtext(6, "\n      ");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const iconTemplateOrComponent_r2 = i0.ɵɵreference(5);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.isIconString)("ngIfElse", iconTemplateOrComponent_r2);
  }
}
function HotToastComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "\n        ");
    i0.ɵɵelement(1, "hot-toast-indicator", 12);
    i0.ɵɵtext(2, "\n      ");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("theme", ctx_r0.toast.iconTheme)("type", ctx_r0.toast.type);
  }
}
function HotToastComponent_ng_container_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function HotToastComponent_button_21_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function HotToastComponent_button_21_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.close());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", ctx_r0.toast.closeStyle);
  }
}
function HotToastContainerComponent_hot_toast_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "hot-toast", 3);
    i0.ɵɵlistener("height", function HotToastContainerComponent_hot_toast_4_Template_hot_toast_height_0_listener($event) {
      const toast_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.updateHeight($event, toast_r2));
    })("beforeClosed", function HotToastContainerComponent_hot_toast_4_Template_hot_toast_beforeClosed_0_listener() {
      const toast_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.beforeClosed(toast_r2));
    })("afterClosed", function HotToastContainerComponent_hot_toast_4_Template_hot_toast_afterClosed_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.afterClosed($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const toast_r2 = ctx.$implicit;
    const i_r4 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("toast", toast_r2)("offset", ctx_r2.calculateOffset(toast_r2.id, toast_r2.position))("toastRef", ctx_r2.toastRefs[i_r4]);
  }
}
const HOT_TOAST_DEFAULT_TIMEOUTS = {
  blank: 4000,
  error: 4000,
  success: 4000,
  loading: 30000,
  warning: 4000,
  info: 4000
};
const EXIT_ANIMATION_DURATION = 800;
const ENTER_ANIMATION_DURATION = 350;
const HOT_TOAST_MARGIN = 8;
class HotToastRef {
  constructor(toast) {
    this.toast = toast;
    /** Subject for notifying the user that the toast has been closed. */
    this._onClosed = new Subject();
  }
  get data() {
    return this.toast.data;
  }
  set dispose(value) {
    this._dispose = value;
  }
  getToast() {
    return this.toast;
  }
  /**Used for internal purpose
   * Attach ToastRef to container
   */
  appendTo(container) {
    const {
      dispose,
      updateMessage,
      updateToast,
      afterClosed
    } = container.addToast(this);
    this.dispose = dispose;
    this.updateMessage = updateMessage;
    this.updateToast = updateToast;
    this.afterClosed = race(this._onClosed.asObservable(), afterClosed);
    return this;
  }
  /**
   * Closes the toast
   *
   * @param [closeData={ dismissedByAction: false }] -
   * Make sure to pass { dismissedByAction: true } when closing from template
   * @memberof HotToastRef
   */
  close(closeData = {
    dismissedByAction: false
  }) {
    this._dispose();
    this._onClosed.next({
      dismissedByAction: closeData.dismissedByAction,
      id: this.toast.id
    });
    this._onClosed.complete();
  }
}
const animate = (element, value) => {
  element.style.animation = value;
};
class AnimatedIconComponent {}
AnimatedIconComponent.ɵfac = function AnimatedIconComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AnimatedIconComponent)();
};
AnimatedIconComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AnimatedIconComponent,
  selectors: [["hot-toast-animated-icon"]],
  inputs: {
    iconTheme: "iconTheme"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 5,
  vars: 2,
  consts: [[1, "hot-toast-animated-icon"]],
  template: function AnimatedIconComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtext(1, "\n  ");
      i0.ɵɵprojection(2);
      i0.ɵɵtext(3, "\n");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(4, "\n");
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("color", ctx.iconTheme == null ? null : ctx.iconTheme.primary);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnimatedIconComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-animated-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"hot-toast-animated-icon\" [style.color]=\"iconTheme?.primary\">\n  <ng-content></ng-content>\n</div>\n"
    }]
  }], null, {
    iconTheme: [{
      type: Input
    }]
  });
})();
class CheckMarkComponent {}
CheckMarkComponent.ɵfac = function CheckMarkComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CheckMarkComponent)();
};
CheckMarkComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CheckMarkComponent,
  selectors: [["hot-toast-checkmark"]],
  inputs: {
    theme: "theme"
  },
  standalone: false,
  decls: 2,
  vars: 4,
  consts: [[1, "hot-toast-checkmark-icon"]],
  template: function CheckMarkComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("--check-primary", ctx.theme == null ? null : ctx.theme.primary)("--check-secondary", ctx.theme == null ? null : ctx.theme.secondary);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckMarkComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-checkmark',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-checkmark-icon\"\n  [style.--check-primary]=\"theme?.primary\"\n  [style.--check-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class ErrorComponent {}
ErrorComponent.ɵfac = function ErrorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ErrorComponent)();
};
ErrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ErrorComponent,
  selectors: [["hot-toast-error"]],
  inputs: {
    theme: "theme"
  },
  standalone: false,
  decls: 2,
  vars: 4,
  consts: [[1, "hot-toast-error-icon"]],
  template: function ErrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("--error-primary", ctx.theme == null ? null : ctx.theme.primary)("--error-secondary", ctx.theme == null ? null : ctx.theme.secondary);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-error',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-error-icon\"\n  [style.--error-primary]=\"theme?.primary\"\n  [style.--error-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class LoaderComponent {}
LoaderComponent.ɵfac = function LoaderComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoaderComponent)();
};
LoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LoaderComponent,
  selectors: [["hot-toast-loader"]],
  inputs: {
    theme: "theme"
  },
  standalone: false,
  decls: 2,
  vars: 4,
  consts: [[1, "hot-toast-loader-icon", 3, "ngStyle"]],
  template: function LoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(1, _c1, ctx.theme == null ? null : ctx.theme.primary, ctx.theme == null ? null : ctx.theme.secondary));
    }
  },
  dependencies: [i1.NgStyle],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoaderComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-loader-icon\"\n  [ngStyle]=\"{ 'border-color': theme?.primary, 'border-right-color': theme?.secondary }\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class WarningComponent {}
WarningComponent.ɵfac = function WarningComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || WarningComponent)();
};
WarningComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: WarningComponent,
  selectors: [["hot-toast-warning"]],
  inputs: {
    theme: "theme"
  },
  standalone: false,
  decls: 2,
  vars: 4,
  consts: [[1, "hot-toast-warning-icon"]],
  template: function WarningComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("--warn-primary", ctx.theme == null ? null : ctx.theme.primary)("--warn-secondary", ctx.theme == null ? null : ctx.theme.secondary);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WarningComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-warning',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-warning-icon\"\n  [style.--warn-primary]=\"theme?.primary\"\n  [style.--warn-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class InfoComponent {}
InfoComponent.ɵfac = function InfoComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || InfoComponent)();
};
InfoComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: InfoComponent,
  selectors: [["hot-toast-info"]],
  inputs: {
    theme: "theme"
  },
  standalone: false,
  decls: 2,
  vars: 4,
  consts: [[1, "hot-toast-info-icon"]],
  template: function InfoComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("--warn-primary", ctx.theme == null ? null : ctx.theme.primary)("--warn-secondary", ctx.theme == null ? null : ctx.theme.secondary);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InfoComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-info-icon\"\n  [style.--warn-primary]=\"theme?.primary\"\n  [style.--warn-secondary]=\"theme?.secondary\"\n></div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }]
  });
})();
class IndicatorComponent {}
IndicatorComponent.ɵfac = function IndicatorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IndicatorComponent)();
};
IndicatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IndicatorComponent,
  selectors: [["hot-toast-indicator"]],
  inputs: {
    theme: "theme",
    type: "type"
  },
  standalone: false,
  decls: 2,
  vars: 1,
  consts: [["class", "hot-toast-indicator-wrapper", 4, "ngIf"], [1, "hot-toast-indicator-wrapper"], [3, "theme"], ["class", "hot-toast-status-wrapper", 4, "ngIf"], [1, "hot-toast-status-wrapper"], [3, "ngSwitch"], [4, "ngSwitchCase"]],
  template: function IndicatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, IndicatorComponent_div_0_Template, 6, 2, "div", 0);
      i0.ɵɵtext(1, "\n");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.type !== "blank");
    }
  },
  dependencies: [i1.NgIf, i1.NgSwitch, i1.NgSwitchCase, CheckMarkComponent, ErrorComponent, LoaderComponent, WarningComponent, InfoComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div *ngIf=\"type !== 'blank'\" class=\"hot-toast-indicator-wrapper\">\n  <hot-toast-loader [theme]=\"theme\"></hot-toast-loader>\n  <div *ngIf=\"type !== 'loading'\" class=\"hot-toast-status-wrapper\">\n    <div [ngSwitch]=\"type\">\n      <div *ngSwitchCase=\"'error'\">\n        <hot-toast-error [theme]=\"theme\"></hot-toast-error>\n      </div>\n      <div *ngSwitchCase=\"'success'\">\n        <hot-toast-checkmark [theme]=\"theme\"></hot-toast-checkmark>\n      </div>\n      <div *ngSwitchCase=\"'warning'\">\n        <hot-toast-warning [theme]=\"theme\"></hot-toast-warning>\n      </div>\n      <div *ngSwitchCase=\"'info'\">\n        <hot-toast-info [theme]=\"theme\"></hot-toast-info>\n      </div>\n    </div>\n  </div>\n</div>\n"
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    type: [{
      type: Input
    }]
  });
})();
class HotToastComponent {
  constructor(injector, renderer, ngZone) {
    this.injector = injector;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.offset = 0;
    this.height = new EventEmitter();
    this.beforeClosed = new EventEmitter();
    this.afterClosed = new EventEmitter();
    this.isManualClose = false;
    this.unlisteners = [];
  }
  get toastBarBaseHeight() {
    return this.toastBarBase.nativeElement.offsetHeight;
  }
  get containerPositionStyle() {
    const top = this.toast.position.includes('top');
    const verticalStyle = top ? {
      top: 0
    } : {
      bottom: 0
    };
    const horizontalStyle = this.toast.position.includes('left') ? {
      left: 0
    } : this.toast.position.includes('right') ? {
      right: 0
    } : {
      left: 0,
      right: 0,
      justifyContent: 'center'
    };
    return {
      transform: `translateY(${this.offset * (top ? 1 : -1)}px)`,
      ...verticalStyle,
      ...horizontalStyle
    };
  }
  get toastBarBaseStyles() {
    const top = this.toast.position.includes('top');
    const enterAnimation = `hotToastEnterAnimation${top ? 'Negative' : 'Positive'} ${ENTER_ANIMATION_DURATION}ms cubic-bezier(0.21, 1.02, 0.73, 1) forwards`;
    const exitAnimation = `hotToastExitAnimation${top ? 'Negative' : 'Positive'} ${EXIT_ANIMATION_DURATION}ms forwards cubic-bezier(0.06, 0.71, 0.55, 1) ${this.toast.duration}ms`;
    const animation = this.toast.autoClose ? `${enterAnimation}, ${exitAnimation}` : enterAnimation;
    return {
      ...this.toast.style,
      animation
    };
  }
  get isIconString() {
    return typeof this.toast.icon === 'string';
  }
  ngOnChanges(changes) {
    if (changes.toast && !changes.toast.firstChange && changes.toast.currentValue?.message) {
      requestAnimationFrame(() => {
        this.height.emit(this.toastBarBase.nativeElement.offsetHeight);
      });
    }
  }
  ngOnInit() {
    if (isTemplateRef(this.toast.message)) {
      this.context = {
        $implicit: this.toastRef
      };
    }
    if (isComponent(this.toast.message)) {
      this.toastComponentInjector = Injector.create({
        providers: [{
          provide: HotToastRef,
          useValue: this.toastRef
        }],
        parent: this.toast.injector || this.injector
      });
    }
  }
  ngAfterViewInit() {
    const nativeElement = this.toastBarBase.nativeElement;
    // Caretaker note: accessing `offsetHeight` triggers the whole layout update.
    // Macro tasks (like `setTimeout`) might be executed within the current rendering frame and cause a frame drop.
    requestAnimationFrame(() => {
      this.height.emit(nativeElement.offsetHeight);
    });
    // Caretaker note: `animationstart` and `animationend` events are event tasks that trigger change detection.
    // We'd want to trigger the change detection only if it's an exit animation.
    this.ngZone.runOutsideAngular(() => {
      this.unlisteners.push(
      // Caretaker note: we have to remove these event listeners at the end (even if the element is removed from DOM).
      // zone.js stores its `ZoneTask`s within the `nativeElement[Zone.__symbol__('animationstart') + 'false']` property
      // with callback that capture `this`.
      this.renderer.listen(nativeElement, 'animationstart', event => {
        if (this.isExitAnimation(event)) {
          this.ngZone.run(() => this.beforeClosed.emit());
        }
      }), this.renderer.listen(nativeElement, 'animationend', event => {
        if (this.isExitAnimation(event)) {
          this.ngZone.run(() => this.afterClosed.emit({
            dismissedByAction: this.isManualClose,
            id: this.toast.id
          }));
        }
      }));
    });
    this.setToastAttributes();
  }
  close() {
    this.isManualClose = true;
    const top = this.toast.position.includes('top');
    const exitAnimation = `hotToastExitAnimation${top ? 'Negative' : 'Positive'} ${EXIT_ANIMATION_DURATION}ms forwards cubic-bezier(0.06, 0.71, 0.55, 1)`;
    const nativeElement = this.toastBarBase.nativeElement;
    animate(nativeElement, exitAnimation);
  }
  ngOnDestroy() {
    this.close();
    while (this.unlisteners.length) {
      this.unlisteners.pop()();
    }
  }
  isExitAnimation(ev) {
    return ev.animationName.includes('hotToastExitAnimation');
  }
  setToastAttributes() {
    const toastAttributes = this.toast.attributes;
    for (const [key, value] of Object.entries(toastAttributes)) {
      this.renderer.setAttribute(this.toastBarBase.nativeElement, key, value);
    }
  }
}
HotToastComponent.ɵfac = function HotToastComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HotToastComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
HotToastComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HotToastComponent,
  selectors: [["hot-toast"]],
  viewQuery: function HotToastComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toastBarBase = _t.first);
    }
  },
  inputs: {
    toast: "toast",
    offset: "offset",
    defaultConfig: "defaultConfig",
    toastRef: "toastRef"
  },
  outputs: {
    height: "height",
    beforeClosed: "beforeClosed",
    afterClosed: "afterClosed"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 25,
  vars: 14,
  consts: [["hotToastBarBase", ""], ["indicator", ""], ["iconTemplateOrComponent", ""], [1, "hot-toast-bar-base-container", 3, "ngStyle", "ngClass"], [1, "hot-toast-bar-base", 3, "ngStyle", "ngClass"], ["aria-hidden", "true", 1, "hot-toast-icon"], [4, "ngIf", "ngIfElse"], [1, "hot-toast-message"], [4, "dynamicView", "dynamicViewContext", "dynamicViewInjector"], ["type", "button", "class", "hot-toast-close-btn", "aria-label", "Close", 3, "ngStyle", "click", 4, "ngIf"], [3, "iconTheme"], [3, "dynamicView"], [3, "theme", "type"], ["type", "button", "aria-label", "Close", 1, "hot-toast-close-btn", 3, "click", "ngStyle"]],
  template: function HotToastComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 3);
      i0.ɵɵtext(1, "\n  ");
      i0.ɵɵelementStart(2, "div", 4, 0);
      i0.ɵɵtext(4, "\n    ");
      i0.ɵɵelementStart(5, "div", 5);
      i0.ɵɵtext(6, "\n      ");
      i0.ɵɵtemplate(7, HotToastComponent_ng_container_7_Template, 7, 2, "ng-container", 6);
      i0.ɵɵtext(8, "\n\n      ");
      i0.ɵɵtemplate(9, HotToastComponent_ng_template_9_Template, 3, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵtext(11, "\n    ");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(12, "\n\n    ");
      i0.ɵɵelementStart(13, "div", 7);
      i0.ɵɵtext(14, "\n      ");
      i0.ɵɵelementStart(15, "div");
      i0.ɵɵtext(16, "\n        ");
      i0.ɵɵtemplate(17, HotToastComponent_ng_container_17_Template, 1, 0, "ng-container", 8);
      i0.ɵɵtext(18, "\n      ");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(19, "\n    ");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(20, "\n\n    ");
      i0.ɵɵtemplate(21, HotToastComponent_button_21_Template, 1, 1, "button", 9);
      i0.ɵɵtext(22, "\n  ");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(23, "\n");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(24, "\n");
    }
    if (rf & 2) {
      const indicator_r4 = i0.ɵɵreference(10);
      i0.ɵɵproperty("ngStyle", ctx.containerPositionStyle)("ngClass", "hot-toast-theme-" + ctx.toast.theme);
      i0.ɵɵadvance(2);
      i0.ɵɵstyleProp("--hot-toast-animation-state", ctx.isManualClose ? "running" : "paused");
      i0.ɵɵproperty("ngStyle", ctx.toastBarBaseStyles)("ngClass", ctx.toast.className);
      i0.ɵɵattribute("aria-live", ctx.toast.ariaLive)("role", ctx.toast.role);
      i0.ɵɵadvance(5);
      i0.ɵɵproperty("ngIf", ctx.toast.icon !== undefined)("ngIfElse", indicator_r4);
      i0.ɵɵadvance(10);
      i0.ɵɵproperty("dynamicView", ctx.toast.message)("dynamicViewContext", ctx.context)("dynamicViewInjector", ctx.toastComponentInjector);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngIf", ctx.toast.dismissible);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle, i1$1.DynamicViewDirective, AnimatedIconComponent, IndicatorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"hot-toast-bar-base-container\"\n  [ngStyle]=\"containerPositionStyle\"\n  [ngClass]=\"'hot-toast-theme-' + toast.theme\"\n>\n  <div\n    class=\"hot-toast-bar-base\"\n    #hotToastBarBase\n    [ngStyle]=\"toastBarBaseStyles\"\n    [ngClass]=\"toast.className\"\n    [style.--hot-toast-animation-state]=\"isManualClose ? 'running' : 'paused'\"\n    [attr.aria-live]=\"toast.ariaLive\"\n    [attr.role]=\"toast.role\"\n  >\n    <div class=\"hot-toast-icon\" aria-hidden=\"true\">\n      <ng-container *ngIf=\"toast.icon !== undefined; else indicator\">\n        <ng-container *ngIf=\"isIconString; else iconTemplateOrComponent\">\n          <hot-toast-animated-icon [iconTheme]=\"toast.iconTheme\">{{ toast.icon }}</hot-toast-animated-icon>\n        </ng-container>\n        <ng-template #iconTemplateOrComponent>\n          <div>\n            <ng-container [dynamicView]=\"toast.icon\"></ng-container>\n          </div>\n        </ng-template>\n      </ng-container>\n\n      <ng-template #indicator>\n        <hot-toast-indicator [theme]=\"toast.iconTheme\" [type]=\"toast.type\"></hot-toast-indicator>\n      </ng-template>\n    </div>\n\n    <div class=\"hot-toast-message\">\n      <div>\n        <ng-container *dynamicView=\"toast.message; context: context; injector: toastComponentInjector\"></ng-container>\n      </div>\n    </div>\n\n    <button\n      *ngIf=\"toast.dismissible\"\n      (click)=\"close()\"\n      type=\"button\"\n      class=\"hot-toast-close-btn\"\n      aria-label=\"Close\"\n      [ngStyle]=\"toast.closeStyle\"\n    ></button>\n  </div>\n</div>\n"
    }]
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    toast: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    defaultConfig: [{
      type: Input
    }],
    toastRef: [{
      type: Input
    }],
    height: [{
      type: Output
    }],
    beforeClosed: [{
      type: Output
    }],
    afterClosed: [{
      type: Output
    }],
    toastBarBase: [{
      type: ViewChild,
      args: ['hotToastBarBase']
    }]
  });
})();
class HotToastContainerComponent {
  constructor(cdr) {
    this.cdr = cdr;
    this.toasts = [];
    this.toastRefs = [];
    /** Subject for notifying the user that the toast has been closed. */
    this._onClosed = new Subject();
    this.onClosed$ = this._onClosed.asObservable();
  }
  trackById(index, toast) {
    return toast.id;
  }
  calculateOffset(toastId, position) {
    const visibleToasts = this.toasts.filter(t => t.visible && t.position === position);
    const index = visibleToasts.findIndex(toast => toast.id === toastId);
    const offset = index !== -1 ? visibleToasts.slice(...(this.defaultConfig.reverseOrder ? [index + 1] : [0, index])).reduce((acc, t) => acc + (t.height || 0) + HOT_TOAST_MARGIN, 0) : 0;
    return offset;
  }
  updateHeight(height, toast) {
    toast.height = height;
    this.cdr.detectChanges();
  }
  addToast(ref) {
    this.toastRefs.push(ref);
    const toast = ref.getToast();
    this.toasts.push(ref.getToast());
    this.cdr.detectChanges();
    return {
      dispose: () => {
        this.closeToast(toast.id);
      },
      updateMessage: message => {
        toast.message = message;
        this.updateToasts(toast);
        this.cdr.detectChanges();
      },
      updateToast: options => {
        this.updateToasts(toast, options);
        this.cdr.detectChanges();
      },
      afterClosed: this.getAfterClosed(toast)
    };
  }
  closeToast(id) {
    if (id) {
      const comp = this.hotToastComponentList.find(item => item.toast.id === id);
      if (comp) {
        comp.close();
      }
    } else {
      this.hotToastComponentList.forEach(comp => comp.close());
    }
  }
  beforeClosed(toast) {
    toast.visible = false;
  }
  afterClosed(closeToast) {
    const toastIndex = this.toasts.findIndex(t => t.id === closeToast.id);
    if (toastIndex > -1) {
      this._onClosed.next(closeToast);
      this.toasts = this.toasts.filter(t => t.id !== closeToast.id);
      this.toastRefs = this.toastRefs.filter(t => t.getToast().id !== closeToast.id);
      this.cdr.detectChanges();
    }
  }
  hasToast(id) {
    return this.toasts.findIndex(t => t.id === id) > -1;
  }
  getAfterClosed(toast) {
    return this.onClosed$.pipe(filter(v => v.id === toast.id));
  }
  updateToasts(toast, options) {
    this.toasts = this.toasts.map(t => ({
      ...t,
      ...(t.id === toast.id && {
        ...toast,
        ...options
      })
    }));
  }
}
HotToastContainerComponent.ɵfac = function HotToastContainerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HotToastContainerComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
HotToastContainerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HotToastContainerComponent,
  selectors: [["hot-toast-container"]],
  viewQuery: function HotToastContainerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(HotToastComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hotToastComponentList = _t);
    }
  },
  inputs: {
    defaultConfig: "defaultConfig"
  },
  standalone: false,
  decls: 8,
  vars: 2,
  consts: [[2, "position", "fixed", "z-index", "9999", "top", "0", "right", "0", "bottom", "0", "left", "0", "pointer-events", "none"], [2, "position", "relative", "height", "100%"], [3, "toast", "offset", "toastRef", "height", "beforeClosed", "afterClosed", 4, "ngFor", "ngForOf", "ngForTrackBy"], [3, "height", "beforeClosed", "afterClosed", "toast", "offset", "toastRef"]],
  template: function HotToastContainerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtext(1, "\n  ");
      i0.ɵɵelementStart(2, "div", 1);
      i0.ɵɵtext(3, "\n    ");
      i0.ɵɵtemplate(4, HotToastContainerComponent_hot_toast_4_Template, 1, 3, "hot-toast", 2);
      i0.ɵɵtext(5, "\n  ");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(6, "\n");
      i0.ɵɵelementEnd();
      i0.ɵɵtext(7, "\n");
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngForOf", ctx.toasts)("ngForTrackBy", ctx.trackById);
    }
  },
  dependencies: [i1.NgForOf, HotToastComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastContainerComponent, [{
    type: Component,
    args: [{
      selector: 'hot-toast-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div style=\"position: fixed; z-index: 9999; top: 0; right: 0; bottom: 0; left: 0; pointer-events: none\">\n  <div style=\"position: relative; height: 100%\">\n    <hot-toast\n      *ngFor=\"let toast of toasts; trackBy: trackById; let i = index\"\n      [toast]=\"toast\"\n      [offset]=\"calculateOffset(toast.id, toast.position)\"\n      [toastRef]=\"toastRefs[i]\"\n      (height)=\"updateHeight($event, toast)\"\n      (beforeClosed)=\"beforeClosed(toast)\"\n      (afterClosed)=\"afterClosed($event)\"\n    ></hot-toast>\n  </div>\n</div>\n"
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    defaultConfig: [{
      type: Input
    }],
    hotToastComponentList: [{
      type: ViewChildren,
      args: [HotToastComponent]
    }]
  });
})();
class ToastConfig {
  constructor() {
    /**
     * Sets the reverse order for hot-toast stacking
     *
     * @default false
     */
    this.reverseOrder = false;
    this.ariaLive = 'polite';
    this.role = 'status';
    this.position = 'top-center';
    this.autoClose = true;
    this.theme = 'toast';
    this.attributes = {};
    // key in ToastType
    this.info = {
      content: ''
    };
    this.success = {
      content: ''
    };
    this.error = {
      content: ''
    };
    this.loading = {
      content: ''
    };
    this.blank = {
      content: ''
    };
    this.warning = {
      content: ''
    };
  }
}
const isFunction = valOrFunction => typeof valOrFunction === 'function';
const resolveValueOrFunction = (valOrFunction, arg) => isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction;
class ToastPersistConfig {
  constructor() {
    /**
     *In which storage id vs. counts should be stored
     *
     * @type {('local' | 'session')}
     * @memberof ToastPersistConfig
     * @default 'local'
     */
    this.storage = 'local';
    /**
     *The key pattern to store object in storage. `${id}` in pattern is replaced with actual toast id.
     *
     * @type {('local' | 'session')}
     * @memberof ToastPersistConfig
     * @default 'ngneat/hottoast-${id}'
     */
    this.key = 'ngneat/hototast-${id}';
    /**
     *The number of toasts allowed to show.
     *
     * @memberof ToastPersistConfig
     * @default 1
     */
    this.count = 1;
    this.enabled = false;
  }
}
class HotToastService {
  constructor(_viewService, platformId, config) {
    this._viewService = _viewService;
    this.platformId = platformId;
    this._isInitialized = false;
    this._defaultConfig = new ToastConfig();
    this._defaultPersistConfig = new ToastPersistConfig();
    if (config) {
      this._defaultConfig = {
        ...this._defaultConfig,
        ...config
      };
    }
  }
  get defaultConfig() {
    return this._defaultConfig;
  }
  set defaultConfig(config) {
    this._defaultConfig = {
      ...this._defaultConfig,
      ...config
    };
    if (this._componentRef) {
      this._componentRef.setInput('defaultConfig', this._defaultConfig);
    }
  }
  /**
   * Opens up an hot-toast without any pre-configurations
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  show(message, options) {
    const toast = this.createToast(message || this._defaultConfig.blank.content, 'blank', {
      ...this._defaultConfig,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for error state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  error(message, options) {
    const toast = this.createToast(message || this._defaultConfig.error.content, 'error', {
      ...this._defaultConfig,
      ...this._defaultConfig?.error,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for success state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  success(message, options) {
    const toast = this.createToast(message || this._defaultConfig.success.content, 'success', {
      ...this._defaultConfig,
      ...this._defaultConfig?.success,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for loading state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  loading(message, options) {
    const toast = this.createToast(message || this._defaultConfig.loading.content, 'loading', {
      ...this._defaultConfig,
      ...this._defaultConfig?.loading,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for warning state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   */
  warning(message, options) {
    const toast = this.createToast(message || this._defaultConfig.warning.content, 'warning', {
      ...this._defaultConfig,
      ...this._defaultConfig?.warning,
      ...options
    });
    return toast;
  }
  /**
   * Opens up an hot-toast with pre-configurations for info state
   *
   * @param message The message to show in the hot-toast.
   * @param [options] Additional configuration options for the hot-toast.
   * @returns
   * @memberof HotToastService
   * @since 3.3.0
   */
  info(message, options) {
    const toast = this.createToast(message || this._defaultConfig.info.content, 'info', {
      ...this._defaultConfig,
      ...this._defaultConfig?.info,
      ...options
    });
    return toast;
  }
  /**
   *
   *  Opens up an hot-toast with pre-configurations for loading initially and then changes state based on messages
   *
   * @template T Type of observable
   * @param messages Messages for each state i.e. loading, success and error
   * @returns
   * @memberof HotToastService
   */
  observe(messages) {
    return source => {
      let toastRef;
      let start = 0;
      const loadingContent = messages.loading ?? this._defaultConfig.loading?.content;
      const successContent = messages.success ?? this._defaultConfig.success?.content;
      const errorContent = messages.error ?? this._defaultConfig.error?.content;
      return defer(() => {
        if (loadingContent) {
          toastRef = this.createLoadingToast(loadingContent);
          start = Date.now();
        }
        return source.pipe(tap({
          ...(successContent && {
            next: val => {
              toastRef = this.createOrUpdateToast(messages, val, toastRef, 'success', start === 0 ? start : Date.now() - start);
            }
          }),
          ...(errorContent && {
            error: e => {
              toastRef = this.createOrUpdateToast(messages, e, toastRef, 'error', start === 0 ? start : Date.now() - start);
            }
          })
        }));
      });
    };
  }
  /**
   * Closes the hot-toast
   *
   * @param [id] - ID of the toast
   * @since 3.0.1 - If ID is not provided, all toasts will be closed
   */
  close(id) {
    if (this._componentRef) {
      this._componentRef.ref.instance.closeToast(id);
    }
  }
  /**
   * Used for internal purpose only.
   * Creates a container component and attaches it to document.body.
   */
  init() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this._componentRef = this._viewService.createComponent(HotToastContainerComponent).setInput('defaultConfig', this._defaultConfig).appendTo(document.body);
  }
  createOrUpdateToast(messages, val, toastRef, type, diff) {
    let content = null;
    let options = {};
    ({
      content,
      options
    } = this.getContentAndOptions(type, messages[type] || (this._defaultConfig[type] ? this._defaultConfig[type].content : '')));
    content = resolveValueOrFunction(content, val);
    if (toastRef) {
      toastRef.updateMessage(content);
      const updatedOptions = {
        type,
        duration: diff + HOT_TOAST_DEFAULT_TIMEOUTS[type],
        ...options,
        ...(options.duration && {
          duration: diff + options.duration
        })
      };
      toastRef.updateToast(updatedOptions);
    } else {
      this.createToast(content, type, options);
    }
    return toastRef;
  }
  createToast(message, type, options, observableMessages) {
    if (!this._isInitialized) {
      this._isInitialized = true;
      this.init();
    }
    const now = Date.now();
    const id = options?.id ?? now.toString();
    if (!this.isDuplicate(id) && (!options.persist?.enabled || options.persist?.enabled && this.handleStorageValue(id, options))) {
      const toast = {
        ariaLive: options?.ariaLive ?? 'polite',
        createdAt: now,
        duration: options?.duration ?? HOT_TOAST_DEFAULT_TIMEOUTS[type],
        id,
        message,
        role: options?.role ?? 'status',
        type,
        visible: true,
        observableMessages: observableMessages ?? undefined,
        ...options
      };
      return new HotToastRef(toast).appendTo(this._componentRef.ref.instance);
    }
  }
  /**
   * Checks whether any toast with same id is present.
   *
   * @private
   * @param id - Toast ID
   */
  isDuplicate(id) {
    return this._componentRef.ref.instance.hasToast(id);
  }
  /**
   * Creates an entry in local or session storage with count ${defaultConfig.persist.count}, if not present.
   * If present in storage, reduces the count
   * and returns the count.
   * Count can not be less than 0.
   */
  handleStorageValue(id, options) {
    let count = 1;
    const persist = {
      ...this._defaultPersistConfig,
      ...options.persist
    };
    const storage = persist.storage === 'local' ? localStorage : sessionStorage;
    const key = persist.key.replace(/\${id}/g, id);
    let item = storage.getItem(key);
    if (item) {
      item = parseInt(item, 10);
      if (item > 0) {
        count = item - 1;
      } else {
        count = item;
      }
    } else {
      count = persist.count;
    }
    storage.setItem(key, count.toString());
    return count;
  }
  getContentAndOptions(toastType, message) {
    let content;
    let options = {
      ...this._defaultConfig,
      ...this._defaultConfig[toastType]
    };
    // typeof message === 'object' won't work, cz TemplateRef's type is object
    if (typeof message === 'string' || isTemplateRef(message) || isComponent(message)) {
      content = message;
    } else {
      let restOptions;
      ({
        content,
        ...restOptions
      } = message);
      options = {
        ...options,
        ...restOptions
      };
    }
    return {
      content,
      options
    };
  }
  createLoadingToast(messages) {
    let content = null;
    let options = {};
    ({
      content,
      options
    } = this.getContentAndOptions('loading', messages));
    return this.loading(content, options);
  }
}
HotToastService.ɵfac = function HotToastService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HotToastService)(i0.ɵɵinject(i1$1.ViewService), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(ToastConfig, 8));
};
HotToastService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: HotToastService,
  factory: HotToastService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1$1.ViewService
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: ToastConfig,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
class HotToastModule {
  static forRoot(config) {
    return {
      ngModule: HotToastModule,
      providers: [{
        provide: ToastConfig,
        useValue: config
      }]
    };
  }
}
HotToastModule.ɵfac = function HotToastModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HotToastModule)();
};
HotToastModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: HotToastModule
});
HotToastModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, DynamicViewModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HotToastModule, [{
    type: NgModule,
    args: [{
      declarations: [HotToastContainerComponent, HotToastComponent, AnimatedIconComponent, IndicatorComponent, CheckMarkComponent, ErrorComponent, LoaderComponent, WarningComponent, InfoComponent],
      imports: [CommonModule, DynamicViewModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of hot-toast
 */

/**
 * Generated bundle index. Do not edit.
 */

export { HotToastModule, HotToastRef, HotToastService, ToastConfig, ToastPersistConfig, resolveValueOrFunction };
